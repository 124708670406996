import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Wrapper } from '@farewill/ui'

import DefaultLayout from 'layouts/DefaultLayout'
import JSONLD from 'components/JSONLD'
import {
  ROOT_URL,
  TRUSTPILOT_RATING_OUT_OF_5,
  TRUSTPILOT_REVIEWS_TOTAL,
  CREMATION_SIMPLE_FEE,
} from 'config'
import { trackLocalFuneralPageVisit } from 'lib/tracking'
import { formatPriceInPounds } from 'lib/formatting/pricing'

import LocalFuneralPageContent from '../LocalFuneralPageContent'
import { parseStructuredData } from '../../helpers'

const LocalFuneralPage = ({ data: { funeralPage, allCrematorium } }) => {
  const { metaTitle, metaDescription, phoneNumber, nameShort } = funeralPage

  const defaultMetaTitle = `Funeral Directors in ${nameShort}`
  const defaultMetaDescription = `Create a meaningful funeral in ${nameShort} from ${formatPriceInPounds(
    CREMATION_SIMPLE_FEE
  )}`

  useEffect(() => {
    trackLocalFuneralPageVisit(phoneNumber)
  }, [])

  return (
    <>
      <Wrapper
        style={{
          position: 'relative',
          overflowX: 'hidden',
        }}
      >
        <JSONLD
          data={[
            {
              '@context': 'https://schema.org/',
              '@type': 'Product',
              name: 'Farewill Funerals',
              brand: 'Farewill',
              image: `${ROOT_URL}/logos/share.jpg`,
              description:
                "From a simple service at your local crematorium, to a family gathering by the sea, we'll help you create a beautiful send-off at an affordable price.",
              aggregateRating: {
                '@type': 'AggregateRating',
                bestRating: '5',
                ratingCount: TRUSTPILOT_REVIEWS_TOTAL,
                ratingValue: TRUSTPILOT_RATING_OUT_OF_5,
                worstRating: '1',
              },
            },
            parseStructuredData(funeralPage.structuredData),
          ]}
        />
        <DefaultLayout
          title={metaTitle || defaultMetaTitle}
          description={
            metaDescription?.metaDescription || defaultMetaDescription
          }
          telephoneNumber={phoneNumber}
          usePhoneNumberTracking={false}
        >
          <LocalFuneralPageContent
            data={funeralPage}
            allCrematorium={allCrematorium}
          />
        </DefaultLayout>
      </Wrapper>
    </>
  )
}

LocalFuneralPage.propTypes = {
  data: PropTypes.shape({
    funeralPage: PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      metaTitle: PropTypes.string.isRequired,
      metaDescription: PropTypes.shape({
        metaDescription: PropTypes.string,
      }),
      phoneNumber: PropTypes.string.isRequired,
      nameShort: PropTypes.string.isRequired,
      structuredData: PropTypes.shape({
        internal: PropTypes.shape({
          content: PropTypes.string,
        }),
      }),
    }),
    allCrematorium: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            longitude: PropTypes.number,
            latitude: PropTypes.number,
            id: PropTypes.string,
            externalId: PropTypes.string,
            name: PropTypes.string,
          }),
        })
      ),
    }).isRequired,
  }).isRequired,
}

export const query = graphql`
  query PublicLocalFuneralPage($slug: String!) {
    funeralPage: contentfulLocalFuneralPage(slug: { eq: $slug }) {
      ...LocalFuneralPage
    }
    allCrematorium {
      edges {
        node {
          ...SingleCrematorium
        }
      }
    }
  }
`

export default LocalFuneralPage
